<template>
    <div class="cs-block cs-calculation" :class="styleClass">
        <div class="cs-base-block">
            <div class="container">
                <div class="header-content">
                    <h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
                    <span class="cs-text" v-html="data.text"></span>
                </div>
                <CSCalculation :id="data.id" />
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'Calculation',
    components: {
        CSCalculation: () => import('../components/CSCalculation.vue'),
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: computed('Calculation')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
$border_radius: 5px;
$input_radius: 0px;
$border_color: #fff;
$placeholder_color: #475467;
$focus_input_color: #748E63;
$bg_input_color: #fff;

@mixin placeholder {
    font-size: 14px;
    font-weight: 400;
    color: $placeholder_color !important;
}

.cs-calculation {
    .header-content {
        text-align: center;
        margin-bottom: 10px;
    }
    ::v-deep {
        .base-card {
            background: #F7F7F7 !important;
            @media (max-width: 767px) {
                padding: 10px !important;
            }
            .variable-input-container {
                @media (max-width: 767px) {
                    padding-right: calc(2rem + 10px) !important;
                }
            }
        }
        .variable-input-container input {
            @include placeholder;
            background-color: $bg_input_color;
            border: 1px solid $border_color !important;
            border-radius: $input_radius !important;
            padding: 20px !important;
            &::placeholder {
                @include placeholder;
            }
            &:focus {
                border-color: $focus_input_color !important;
            }
        }
        .variable-select {
            .selectable > button.dropdown-toggle {
                border-color: $border_color !important;
            }
            .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
                box-shadow: none !important;
            }
            .dropdown {
                button:not(.dropdown-menu button) {
                    @include placeholder;
                    border-radius: $input_radius;
                    padding: 20px !important;
                    cursor: pointer;
                    background-color: #fff;
                    width: 100%;
                    outline: none;
                    &:focus {
                        border-color: $focus_input_color !important;
                    }
                }
                .dropdown-menu {
                    border-radius: $input_radius !important;
                    border: 1px solid $focus_input_color !important;
                }
                .dropdown-item {
                    @include base-transition;
                    @include placeholder;
                }
                .dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
                    color: #333;
                    text-decoration: none;
                    background-color: #eee;
                }
            }
        }
        .btn.btn-primary {
            @include base-transition;
            background: $theme_color !important;
            color: $secondary_text_color !important;
            font-weight: 500;
            border: 1px solid $theme_color;
            min-width: 130px;
            padding: 15px !important;
            border-radius: 0px !important;
            min-width: 150px;
            display: flex;
            justify-content: center;
            &:hover {
                border-color: $theme_color !important;
                background: transparent !important;
                color: $secondary_text_color !important;
            }
            &:focus {
                box-shadow: none !important;
            }
            display: block;
            margin: 30px auto 0;
        }
        .variables_error {
            max-width: 100% !important;
            text-align: center;
            .view-description {
                font-family: "Roboto";
                font-weight: 400;
                font-size: 16px;
                a {
                    color: #748E63;
                    font-weight: 600;
                }
            }
            .view-img {
                margin-top: 20px;
                display: block;
                height: 400px;
            }
        }
        .tooltip-wraper {
            background-color: #748E63 !important;
            box-shadow: 0 0 7px 0.1px #748E63 !important;
        }
        .CS-table {
            box-shadow: 3px 6px 24px 0px #B8C1CE4D;
            .table {
                border-radius: $border_radius;
                border: none;
                background: #fff !important;
                margin-bottom: 0;
                .thead {
                    border-radius: $border_radius $border_radius 0 0;
                    background: #F7F7F7;
                    &__item {
                        border: none !important;
                        border-right: 1px solid #E3EDEB !important;
                        padding: 25px !important;
                        background: transparent !important;
                        color: $secondary_text_color !important;
                        text-align: left !important;
                        font-size: 24px !important;
                        font-weight: 700 !important;
                        &:last-child {
                            border-right: none !important;
                        }
                    }
                }
                .tbody {
                    &__row {
                        border-bottom: 1px solid #E3EDEB !important;
                        &:last-child {
                            border-bottom: none !important;
                        }
                        &:nth-child(even) {
                            background: #F7F7F7;
                        }
                    }
                    &__item {
                        border: none !important;
                        border-right: 1px solid #E3EDEB !important;
                        padding: 20px 25px !important;
                        text-align: left !important;
                        font-weight: 400 !important;
    
                        &:last-child {
                            border-right: none !important;
                        }
                        &.selected {
                            color: #748E63;
                            font-weight: 600 !important;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
</style>